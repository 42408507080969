import getConfig from 'next/config';
import { Auth } from 'aws-amplify';
import { ACTION_FAIL, ACTION_SUCCESS } from '@actionTypes';
import formatDate from 'vl-common/src/lib/formatDate';
import backendFetch from '@lib/backendFetch';
import { z } from 'zod';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

const { publicRuntimeConfig } = getConfig() || {};

export interface GenericResponse<T> extends Response {
  json: () => Promise<T>;
  clone: () => GenericResponse<T>;
}
type ErrorResponse = GenericResponse<{ message?: string; errorMessage?: string; error?: string }>;

type FetchOptions = {
  method?: RequestInit['method'];
  params?: string;
  noUrl?: boolean;
  /** Is passed to JSON.stringify, which will attempty to serialize anything */
  postBody?: any;
  actionType?: string;
  errorType?: string;
  nobearer?: boolean;
};

export interface VLThunkAction<T> extends ThunkAction<T, any, null, AnyAction> {
  options?: FetchOptions;
  setError?: (error: any) => void;
}

export const requestError = (type?: string, error?: any) => ({
  type,
  error
});

export const requestSuccess = (type?: string, payload?: any, ...props: any[]) => ({
  type,
  payload,
  ...props
});

function dereferenceSoleProperty<T>(data: any): T[] {
  const [soleProperty, ...others] = Object.keys(data);

  if (others.length) {
    // eslint-disable-next-line no-console
    console.error('Multiple properties on returned object:', soleProperty, ...others);
    return data as T[];
  }

  return data[soleProperty] as T[];
}

export class ErrorWithResponse extends Error {
  constructor(
    msg: string,
    public response: Response
  ) {
    super(msg);
  }
}

export async function unpackJson<S extends z.ZodTypeAny>(response: GenericResponse<z.infer<S>>, schema?: S) {
  const text = await response.text();
  const unknownJSON = JSON.parse(text, (key, value) => {
    // reject any date not ending with the UTC "Z" designator. Strickly speaking this is
    // over constrained (see https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) but the
    // backend has been configured to conform to the format below, despite the fact that ISO 8601
    // supports many different date patterns.
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[A-Z]/.test(value)) {
      return new Date(value);
    }
    return value;
  });

  if (!schema) return unknownJSON as ReturnType<typeof response.json>;

  if (process.env.NODE_ENV === 'development') {
    const json = schema.safeParse(unknownJSON);

    if (!json.success) {
      console.warn('Failed to parse JSON', json.error.issues);
    }
  }

  return unknownJSON as z.infer<typeof schema>;
}

function formatDates(key: string, obj: any) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [
        key,
        value instanceof Date ? formatDate(value, 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z') : value
      ];
    })
  );
}

export const fetchData = <S extends z.ZodTypeAny, FO extends FetchOptions>(
  fetchOptions: FO,
  schema?: S
): VLThunkAction<FO['actionType'] extends string ? { payload: z.infer<S>; type: string; error?: any } : z.infer<S>> => {
  const {
    method = 'GET',
    params = undefined,
    noUrl = true,
    postBody = undefined,
    actionType = undefined,
    errorType = undefined,
    nobearer = false
  } = fetchOptions;

  const contentType: {
    method: RequestInit['method'];
    headers: {
      'Content-Type': string;
      Authorization?: string;
    };
  } = { method, headers: { 'Content-Type': 'application/json', Authorization: undefined } };
  const bodyParams = { body: JSON.stringify(postBody, formatDates), method };
  const headers = { ...contentType, ...bodyParams, redirect: 'follow' };
  const baseHeader = method === 'GET' ? contentType : headers;

  const handler: VLThunkAction<z.infer<S>> = async (dispatch) => {
    const { API_URL, TOAST_MESSAGES = '1' } = publicRuntimeConfig;

    const handlerFunction = handler.setError;

    const raiseError = async (response: ErrorResponse) => {
      const message = `An API invocation failed (${response.status}):\n${JSON.stringify(fetchOptions, null, 2)}`;
      const toastsEnabled = Number(TOAST_MESSAGES) !== 0;
      const json = await response
        .clone()
        .json()
        .catch(() => null);

      // if the backend has returned an error always toast it ... only toast our generic "message" toast
      // if toasts are enabled
      if (handlerFunction && (toastsEnabled || json?.errorMessage || json?.message)) {
        handlerFunction(new ErrorWithResponse(message, response));
      } else {
        console.log(`No error handler, ${message}`);
      }

      return errorType
        ? dispatch(requestError(errorType, response))
        : new Response(null, { status: response.status, statusText: json?.message || '' });
    };

    try {
      let currentSession = null;
      let userParams = params;

      // check the cognito token is valid
      try {
        currentSession = await Auth.currentSession();
        baseHeader.headers.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
      } catch (e) {
        // barf if this is an authenticated call
        if (nobearer === false) {
          // eslint-disable-next-line no-console
          return dispatch(requestError(errorType || ACTION_FAIL, e));
        }
      }

      // add the user guid if required
      if (params?.includes('APPUSER')) {
        try {
          userParams = params.replace(/APPUSER/gi, currentSession?.getIdToken().payload.sub);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Failed to substitute APPUSER');
          return dispatch(requestError(errorType, e));
        }
      }
      const requestParams = noUrl ? `${API_URL}/${userParams}` : params ?? '';

      const response = await backendFetch<z.infer<S>>(requestParams, {
        ...baseHeader,
        dispatch,
        unauthenticated: nobearer
      });

      if (Number(response.status) === 202 || Number(response.status) === 204) {
        const json = await unpackJson(response, schema).catch(() => null);
        // backwards compatibility
        if (json && Number(response.status) === 202 && !actionType) {
          return json;
        }
        return dispatch(requestSuccess(actionType || ACTION_SUCCESS, json));
      }

      if (Number(response.status) === 205) {
        return dispatch(requestSuccess(actionType || ACTION_FAIL, { status: 205 }));
      }

      if (Number(response.status) === 409) {
        // requestSuccess merely implies that the response object can be returned to the caller, albeit with a failure type
        return dispatch(requestSuccess(errorType || ACTION_FAIL, response));
      }

      if (Number(response.status) === 417) {
        // Expectation Failed Server error contains an error message that can be displayed to the user
        const json = await unpackJson(response).catch(() => null);
        return dispatch(requestError(errorType || ACTION_FAIL, json));
      }

      if (response.status >= 400 && response.status <= 500) {
        return raiseError(response);
      }

      const data = await unpackJson(response);

      const injectAPIFailures = <T>(data: T) => {
        const { RANDOM_FAILURE_PROBABILITY } = publicRuntimeConfig; // null by default

        // Will not happen in production
        if (RANDOM_FAILURE_PROBABILITY && Math.random() > 1 - Number(RANDOM_FAILURE_PROBABILITY)) {
          return raiseError(response);
        }

        return actionType ? dispatch(requestSuccess(actionType, dereferenceSoleProperty(data))) : data;
      };

      return injectAPIFailures(data);
    } catch (error) {
      console.log(error);

      return raiseError(new Response(JSON.stringify(error), { status: 599 }));
    }
  };

  handler.options = fetchOptions;
  return handler;
};
