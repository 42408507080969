import * as z from 'zod';
import { TelPrefNoEnum } from '../enums/telPrefNo';
import { SpecAreaCodeEnum } from '../enums/specAreaCode';
import { CatCodeEnum } from '../enums/catCode';
import { ClinicianTypeCodeEnum } from '../enums/clinicianTypeCode';
import { ApptTypeCodeEnum } from '../enums/apptTypeCode';
import { CallTypeCodeEnum } from '../enums/callTypeCode';

export const AppointmentSchema = z.object({
  case_id: z.number(),
  client_case_id: z.string(),
  case_title: z.string(),
  spec_cat_code: CatCodeEnum,
  complex_case: z.boolean(),
  cat_desc: z.string(),
  case_spec_area_code: SpecAreaCodeEnum.nullable(),
  case_spec_area: z.string().nullable(),
  condition: z.string(),
  case_created_date: z.coerce.date(),
  appointment_id: z.number(),
  twilio_id: z.union([z.null(), z.string()]),
  appointment_delayed: z.boolean(),
  appointment_delay_mins: z.number(),
  appointment_title: z.string().nullable(),
  appt_type_code: ApptTypeCodeEnum,
  appt_type_desc: z.string(),
  call_type_code: CallTypeCodeEnum.nullable(),
  call_type_desc: z.string().nullable(),
  appointment_type: z.string(),
  patient_user_guid: z.string(),
  lucy_id: z.string(),
  patient_client_name: z.string(),
  patient_client_code: z.string(),
  patient_client_id: z.number(),
  patient_title: z.string(),
  /** The patient's name without a title */
  patient_no_title: z.string(),
  /** The patient name with a title */
  patient: z.string(),
  patient_tel_mobile_no: z.string(),
  patient_tel_other_no: z.string().nullable(),
  patient_postcode: z.string(),
  patient_tel_pref_no: TelPrefNoEnum,
  clinician_user_guid: z.string(),
  /** The clinician's name */
  clinician: z.string(),
  clinician_tel_mobile_no: z.string(),
  clinician_tel_other_no: z.string().nullable(),
  clinician_tel_pref_no: TelPrefNoEnum,
  clinician_type: z.string(),
  clinician_type_code: ClinicianTypeCodeEnum,
  clinician_company: z.string(),
  clinician_spec_areas: z.string().nullable(),
  clinician_avatar_url: z.string().nullable(),
  clinician_bio: z.string().nullable(),
  datetime: z.coerce.date(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  cancelled_date: z.union([z.coerce.date(), z.null()]),
  cancelled_reason: z.union([z.string(), z.null()]),
  status: z.string(),
  appointment_outcome: z.string().nullable(),
  appointment_outcome_code: z.string().nullable(),
  appt_pri_no: z.null(),
  appt_pri_desc: z.null(),
  appt_rtt_no: z.null(),
  appt_rtt_desc: z.null(),
  appointment_completed: z.boolean(),
  appointment_refer: z.boolean(),
  gdpr_resend: z.boolean(),
  case_review: z.boolean(),
  /** Slot duration in minutes. */
  slot_duration: z.number().nullable(),
  letter_saved: z.boolean(),
  ods_code: z.string().nullable(),
  message_id: z.string().nullable(),
  auth_granted: z.union([ClinicianTypeCodeEnum, z.null()]),
  auth_granted_start_date: z.union([z.coerce.date(), z.null()]),
  auth_granted_end_date: z.union([z.coerce.date(), z.null()]),
  prescribe_meds_flag: z.number(),
  read_only: z.boolean(),
  referral_created: z.boolean(),
  reschedule_task_id: z.string().nullable()
});
export type Appointment = z.infer<typeof AppointmentSchema>;
