import { z } from 'zod';

export const PolicySchema = z.object({
  user_guid: z.string(),
  policy_code: z.string(),
  policy_level: z.enum(['S', 'C', 'I']),
  accepted: z.boolean(),
  accepted_date: z.union([z.coerce.date(), z.null()]),
  version_id: z.number(),
  description: z.string(),
  url: z.string().url(),
  policy_link: z.union([z.null(), z.string()])
});

export type Policy = z.infer<typeof PolicySchema>;
