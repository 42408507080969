import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import AudioOutlined from '@ant-design/icons/AudioOutlined';
import CalendarFilled from '@ant-design/icons/CalendarFilled';
import CarryOutFilled from '@ant-design/icons/CarryOutFilled';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CheckSquareFilled from '@ant-design/icons/CheckSquareFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import ContainerFilled from '@ant-design/icons/ContainerFilled';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import HomeFilled from '@ant-design/icons/HomeFilled';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import PhoneFilled from '@ant-design/icons/PhoneFilled';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import QuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import SoundOutlined from '@ant-design/icons/SoundOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import VideoCameraFilled from '@ant-design/icons/VideoCameraFilled';
import SoundFilled from '@ant-design/icons/SoundFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import BgColorsOutlined from '@ant-design/icons/BgColorsOutlined';
import PlusCircleFilled from '@ant-design/icons/PlusCircleFilled';
import CheckSquareTwoTone from '@ant-design/icons/CheckSquareTwoTone';
import PhoneTwoTone from '@ant-design/icons/PhoneTwoTone';
import SendOutlined from '@ant-design/icons/SendOutlined';
import StopTwoTone from '@ant-design/icons/StopTwoTone';
import VideoCameraTwoTone from '@ant-design/icons/VideoCameraTwoTone';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import LockFilled from '@ant-design/icons/LockFilled';
import UnlockFilled from '@ant-design/icons/UnlockFilled';

export {
  LockFilled,
  UnlockFilled,
  CloudUploadOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AudioOutlined,
  CalendarFilled,
  CarryOutFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckSquareFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  ContainerFilled,
  CopyOutlined,
  DownOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileTextOutlined,
  HomeFilled,
  InfoCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
  MoreOutlined,
  PhoneFilled,
  PhoneOutlined,
  QuestionCircleFilled,
  ReloadOutlined,
  RightOutlined,
  SearchOutlined,
  SoundOutlined,
  UpOutlined,
  UserOutlined,
  WarningOutlined,
  VideoCameraFilled,
  SoundFilled,
  InfoCircleFilled,
  PlusCircleFilled,
  BgColorsOutlined,
  CheckSquareTwoTone,
  PhoneTwoTone,
  SendOutlined,
  StopTwoTone,
  VideoCameraTwoTone
};
