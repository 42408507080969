import { useCallback, useEffect, useState } from 'react';
import { type Dispatch, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { usePolicies } from '@hooks/usePolicies';
import * as actions from '@actions';
import ServiceApi from 'vl-common/src/services/service.api';
import { Auth } from 'aws-amplify';
import { useHarReady } from 'vl-common/src/hooks/useHARReady';
import getConfig from 'next/config';

// todo - convert this to a hook???
// loads user and policies into Redux and optionally navigates to /create-account.
export async function maybeRedirectToSetup(dispatch: Dispatch, urlPath: string, redirector: (path: string) => void) {
  // todo - check that the user hasn't already been loaded???

  try {
    const res = await dispatch(actions.userFetch());
    console.log('Check:', res);
    if (res && res.user) {
      if (
        res.user.self_reg_required &&
        res.user.policies_accepted &&
        !urlPath.startsWith('/policy') &&
        !urlPath.startsWith('/create-account') &&
        !urlPath.startsWith('/patients/register')
      ) {
        redirector('/create-account');
      } else {
        ServiceApi.userGuid = res.user.user_guid;
      }
    }
    return res?.user;
  } catch (error) {
    if (window.location.search.includes('har=') || Auth.isInHARMode) return null;

    if (
      !urlPath.startsWith('/login') &&
      !urlPath.startsWith('/wellbeinghub') &&
      !urlPath.startsWith('/login?') &&
      !urlPath.startsWith('/patients/legal') &&
      !urlPath.startsWith('/wellbeinghub?') &&
      !urlPath.startsWith('/forgot-password')
    ) {
      if (sessionStorage.getItem('mocked') !== 'true') {
        console.log(`redirecting to /login because userFetch failed (currently on ${window.location.href})`);
        redirector('/login');
        if (getConfig()?.publicRuntimeConfig?.LOGOUT_ALERT === '1') {
          // eslint-disable-next-line no-alert
          alert(
            `Unauthorised to access: redirecting to /login because userFetch failed (currently on ${window.location.href})`
          );
        }
      }
    }
  }

  return null;
}

export function useInitialisedStore(): boolean {
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useSelector((state) => state.auth);
  const { policiesAccepted } = usePolicies();
  const redirection = useCallback(
    (user) => {
      if (!user || !router?.isReady || Auth.authenticatedUser) return '';

      if (user.user_type_code === 'CLINICIAN' && router.pathname.startsWith('/patients')) {
        return '/clinician';
      }

      if (user.user_type_code === 'PATIENT' && router.pathname.startsWith('/clinician')) {
        return '/patients';
      }

      return '';
    },
    [router?.pathname, router?.isReady]
  );
  const [checked, setChecked] = useState(() => {
    const url = redirection(user);

    if (url) {
      router.push(url).then();
      return false;
    }

    return !!user;
  });
  const { isReady } = useHarReady();

  useEffect(
    () => {
      if (user) {
        ServiceApi.userGuid = user.user_guid;
      }
    },
    [] /* user */
  );

  useEffect(() => {
    if (!user && router?.isReady && !Auth.authenticatedUser) {
      navigator.serviceWorker.ready.then(() =>
        maybeRedirectToSetup(dispatch, router.pathname, (path) => {
          router.push(path).then();
        }).then()
      );
    }
  }, [dispatch, router, user, router?.isReady]);

  useEffect(() => {
    if (user && router?.isReady) {
      if (!Auth.authenticatedUser && !policiesAccepted && !router.pathname.startsWith('/policy')) {
        router.push('/policy');
        return;
      }
      const url = redirection(user);

      if (url) {
        router.push(url).then();
        return;
      }

      setChecked(true);
    }
  }, [user, router?.pathname, redirection, router, policiesAccepted, router?.isReady, isReady]);

  return checked && !!user;
}

export default useInitialisedStore;
