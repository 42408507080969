import React, { useMemo, useState } from 'react';
import { Select } from 'ALIASED-antd';
import type { SelectProps } from 'ALIASED-antd';

interface WrappedSelectProps extends SelectProps {
  'data-testid': string;
}

interface OptionItem {
  value: string | number | null | undefined;
  label: string | null | undefined;
}

const mergePropOptions = (options: SelectProps['options']): OptionItem[] => {
  if (!options) return [];

  return options.flatMap((option) => {
    if ('options' in option && Array.isArray(option.options)) {
      return option.options;
    }
    return option;
  });
};

const mergeChildrenOptions = (children: React.ReactNode): OptionItem[] => {
  if (!children) return [];

  const processChild = (child: React.ReactNode): OptionItem[] => {
    if (!React.isValidElement(child)) return [];

    const { type, props } = child;

    if (type === Select.Option) {
      return [{ value: props.value, label: props.children as string }];
    }

    if (type === Select.OptGroup) {
      const { children: optGroupChildren } = props;

      if (!optGroupChildren) return [];

      return optGroupChildren.flatMap(processChild);
    }

    return [];
  };

  const childrenArray = Array.isArray(children) ? children : [children];
  return childrenArray.flatMap(processChild);
};

export const WrappedAntdSelect: React.FC<WrappedSelectProps> & {
  children?: React.ReactNode;
  Option: typeof Select.Option;
  OptGroup: typeof Select.OptGroup;
} = (props) => {
  const [internalValue, setInternalValue] = useState<OptionItem['value']>(undefined);

  const mergedOptions: OptionItem[] = useMemo(() => {
    if (props.options) {
      // extract and merge options from options props passed in form of array of options and option groups
      return mergePropOptions(props.options);
    }

    if (props.children) {
      // Extract and merge options from Option and OptGroup components if children are provided
      return mergeChildrenOptions(props.children);
    }

    return [];
  }, [props.children, props.options]);

  const simulateTestClick = (value: OptionItem['value'], option: OptionItem) => {
    return () => {
      if (!props.value && !props.onChange && !props.onSelect) {
        setInternalValue(value);
      }
      props.onChange && props.onChange(value, option);
      props.onSelect && props.onSelect(value, option);
    };
  };

  const valueSource = useMemo(() => {
    return props.value !== undefined ? props.value : internalValue;
  }, [props.value, internalValue]);

  return (
    <>
      <Select {...props} value={valueSource} style={{ width: '100%', ...props.style }} />
      {/* Hidden markup for automation tests */}
      <div data-select-value-map={props['data-testid']} className="d-none">
        {mergedOptions?.map((option) => (
          <div
            key={option.value}
            data-value={option.value}
            data-label={option.label}
            onClick={simulateTestClick(option.value, option)}
          />
        ))}
      </div>
    </>
  );
};

WrappedAntdSelect.Option = Select.Option;
WrappedAntdSelect.OptGroup = Select.OptGroup;
