import React from 'react';
import NHSLoginButton from '@components/Login/NHSLoginButton';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import { Button } from 'antd';
import { Auth, Amplify } from 'aws-amplify';
import { css } from '@emotion/react';

const IdentityProviderItem = ({ message, children }: { message?: string; children: React.ReactNode }) => (
  <div className="identity-provider">
    {children}
    <div className="identity-provider-message">{message}</div>
  </div>
);

const IdentityProviderButtons = () => {
  const { IDENTITY_PROVIDERS, APP_CLIENT_ID } = useRuntimeConfig();
  const providers = IDENTITY_PROVIDERS?.providers;

  if (!providers || providers.length === 0) return null;

  return (
    <div
      className="identity-providers"
      css={css`
        display: grid;

        .identity-provider {
          display: flex;
          flex-direction: column;
        }

        .identity-provider-message {
          margin: 0.75em 0 0 0;
          text-align: center;
          font-size: 0.8rem;
        }
      `}
    >
      {providers
        .filter((p) => !p?.provider?.includes('-ERR-'))
        .map?.((identityProvider) => {
          if (!identityProvider) return null;

          const { label, message, provider } = identityProvider;

          const onClick = async () => {
            if (sessionStorage.getItem('restricted')) {
              await Amplify.configure({
                userPoolWebClientId: APP_CLIENT_ID
              });
              sessionStorage.removeItem('restricted');
            }
            // @ts-ignore
            return Auth.federatedSignIn({ provider });
          };

          if (provider === 'NHSLogin') {
            return (
              <IdentityProviderItem key={provider} message={String(message)}>
                <NHSLoginButton data-testid="nhs-login-button" {...{ onClick }} />
              </IdentityProviderItem>
            );
          }

          return (
            <IdentityProviderItem key={provider} message={String(message)}>
              <Button data-testid="sso" size="large" block type="primary" {...{ onClick }}>
                {label}
              </Button>
            </IdentityProviderItem>
          );
        })}
    </div>
  );
};

export default IdentityProviderButtons;
